<template>
    <div class="main" v-loading="loading1">
        <div class="userinfo">
            <img class="header" :src="cdn+'gerenzhongxin/header.png'" />
            <span class="nickname_st">{{ nickname }}</span>
            <router-link to="/myself/jihuo" class="vip_st">
                {{ vip_status }}
            </router-link>
            <button v-if="logout_flag" class="logout" @click="logout">退出登录</button>
        </div>
        <div class="list">
            <div class="list_1">
                <router-link to="/myself/gerenshouye" active-class="active">
                    首 页
                </router-link>
                <router-link to="/myself/ShenqingTixian" active-class="active">
                    申 请 提 现
                </router-link>
                <router-link to="/myself/WodeTuandui" active-class="active" v-if="flag">
                    我 的 团 队
                </router-link>
                <router-link to="/myself/AnquanZhongxin" active-class="active">
                    安 全 中 心
                </router-link>
                <router-link to="/myself/RenwuJilu" active-class="active">
                    任 务 记 录
                </router-link>
                <router-link to="/myself/YongjinJilu" active-class="active" v-if="flag">
                    佣 金 记 录
                </router-link>
                <router-link to="/myself/YonghuXieyi" active-class="active">
                    用 户 协 议
                </router-link>
                <button class="connect_sy" @click="connect">
                    私 人 客 服
                </button>
            </div>
        </div>
        <div class="xinxi">
            <router-view to />
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import global from '@/api/global/global.vue';
import Cookies from 'js-cookie'

export default {
    name: 'Myself',
    data() {
        return {
            nickname: '未登录',
            vip_status: '未激活,点击激活',
            loading1: false,
            flag: false,
            login_flag: false,
            logout_flag: false,
            kefu: {
                visiter_id: null,
                visiter_name: null,
                role: null,
                isActived: null,
                totalPoints: null,
                inviteCode: null,
                spentPoints: null,
                teamTotal: null,
                groupid: null,
            },
            cdn:global.cdn
        }
    },
    methods: {
        logout() {
            this.login_flag = false
            this.logout_flag = false
            this.$emit('fromlogin', this.login_flag);
            Cookies.remove("token")
            this.$router.push({ path: '/login' });
        },
        async connect() {
            this.loading1 = true;
            await axios.get(global.url + '/user/my', {
                headers: { Authorization: "Bearer " + Cookies.get("token") }
            }).then(response => {
                if (response.data.code  != 401) {
                    this.loading1 = false
                    this.kefu.visiter_id = response.data.userId
                    this.kefu.visiter_name = response.data.userName
                    this.kefu.totalPoints = response.data.totalPoints / 10000
                    this.kefu.inviteCode = response.data.inviteCode
                    this.kefu.isActived = response.data.isActive
                    this.kefu.role = response.data.roleId
                    this.kefu.teamTotal = response.data.teamTotal
                    this.kefu.spentPoints = response.data.spentPoints / 10000
                    this.kefu.groupid = 6
                    if (this.kefu.role == 13) {
                        this.kefu.role = '总代理'
                    } else if (this.kefu.role == 12) {
                        this.kefu.role = '高级代理'
                    } else if (this.kefu.role == 11) {
                        this.kefu.role = '初级代理'
                    } else if (this.kefu.role == 10) {
                        if (this.kefu.isActived == 1) {
                            this.kefu.role = 'VIP'
                        } else {
                            this.kefu.role = '普通用户'
                        }
                    }

                    if (this.kefu.isActived == 1) {
                        this.kefu.isActived = '已激活'
                    } else {
                        this.kefu.isActived = '未激活'
                    }                   
                    
                    window.open('https://kefu.rongshihuiys.com/index/index/home?avatar=&visiter_name=' + this.kefu.visiter_name + '&visiter_id=' + this.kefu.visiter_id + '&business_id=2&groupid=' + this.kefu.groupid + '&rsh_user=%7B%22userName%22%3A%22' + this.kefu.visiter_name + '%22%2C%22userld%22%3A%22' + this.kefu.visiter_id + '%22%2C%22role%22%3A%22' + this.kefu.role + '%22%2C%22isActive%22%3A%22' + this.kefu.isActived + '%22%2C%22totalPoints%22%3A%22' + this.kefu.totalPoints + '%22%2C%22spentPoints%22%3A%22' + this.kefu.spentPoints + '%22%2C%22totalTeam%22%3A%22' + this.kefu.teamTotal + '%22%2C%22inviteCode%22%3A%22' + this.kefu.inviteCode + '%22%7D', '_blank')
                } else {
                    this.loading1 = false
                    this.$message("获取失败！"+ response.data.msg);
                }
            }).catch(error => {
                this.loading1 = false;
                this.$message("网络错误！请检查网络或联系客服"+error.msg);
            })
        },

        async fetchData() {
            this.loading1 = true;
            await axios.get(global.url + '/user', {
                headers: { Authorization: "Bearer " + Cookies.get("token") }
            }).then(response => {
                if (response.data.msg == "操作成功") {
                    Cookies.set('role', response.data.data.roles[0].roleKey, { expires: 1 })
                    Cookies.set('inviteId', response.data.inviteCode, { expires: 1 })
                    this.nickname = response.data.data.nickName;
                    Cookies.set('name', response.data.data.nickName, { expires: 1 })
                    this.login_flag = true
                    this.logout_flag = true
                    this.$emit('fromlogin', this.login_flag);
                }
                else {
                    this.$message("获取失败！请登录！");
                    this.login_flag = false
                    this.logout_flag = false
                    this.$emit('fromlogin', this.login_flag);
                    this.$router.push({ path: '/login' });
                }
            }).catch(error => {
                this.$message("网络错误！请检查网络或联系客服");
                
            })
            await axios.get(global.url + '/user/my', {
                headers: { Authorization: "Bearer " + Cookies.get("token") }
            }).then(response => {
                if (response.status == 200) {
                    if (response.data.isActive == 1) {
                        this.vip_status = '已激活'
                    } else {
                        this.vip_status = '未激活,点击激活'
                    }
                } 
            }).catch(error => {
                
            })
            await axios.get(global.url + '/getInfo', {
                headers: { Authorization: "Bearer " + Cookies.get("token") }
            }).then(response => {
                if (response.data.msg == "操作成功") {
                    this.loading1 = false;
                    this.userid = response.data.user.userName;
                    if (response.data.roles[0] == "vip") {
                        this.flag = false
                    } else {
                        this.flag = true
                    }
                } else {
                    this.loading1 = false;
                }
            }).catch(error => {
                this.loading1 = false;
                
            });
        },

    },
    mounted() {
        this.fetchData();
    },
}
</script>

<style long="scss" scoped>
a {
    width: 100%;
    color: white;
    text-align: center;
    font-size: 160%;
    font-family: ziti3;
    text-decoration: none;
    margin-top: 1vw;
}

.active {
    color: #53aaa7;
}

.connect_sy {
    width: 100%;
    color: white;
    text-align: center;
    font-size: 160%;
    font-family: ziti3;
    text-decoration: none;
    border: none;
    margin-top: 1vw;
    background-color: transparent;
}

.main {
    position: relative;
}

.userinfo {
    width: 15%;
    height: 30%;
    position: absolute;
    top: 1%;
    left: 10%;
    background-color: rgba(0, 0, 0, 0.355);
    backdrop-filter: blur(6px);
    border-radius: 1vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.header {
    width: 40%;
    height: 35%;
    margin-top: 0%;
    margin-left: 30%;
}

.nickname_st {
    margin-top: -5%;
    width: 100%;
    color: white;
    text-align: center;
    font-size: 2vw;
    font-family: ziti3;
}

.vip_st {
    margin-bottom: 20%;
    width: 100%;
    height: 10%;
    text-align: center;
    font-size: 1.5vw;
    font-family: ziti3;
    border: none;
}

.logout {
    position: absolute;
    top: 14vw;
    left: 3.5vw;
    width: 8vw;
    height: 1.5vw;
    background-color: #53aaa7;
    font-size: 1.2vw;
    color: white;
    border: none;
    border-radius: 1vw;
    font-family: ziti3;
}

.logout:hover {
    background-color: grey;
}

.list {
    position: absolute;
    width: 15%;
    height: 50%;
    top: 32%;
    left: 10%;
    background-color: rgba(0, 0, 0, 0.355);
    backdrop-filter: blur(6px);
    border-radius: 1vw;
    font-size: 1vw;
}

.xinxi {
    position: absolute;
    width: 63%;
    height: 98%;
    top: 1%;
    right: 10%;
    border-radius: 1rem;
}

.list_1 {
    margin-top: 15%;
    margin-left: 10%;
    width: 80%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
</style>