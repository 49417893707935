<template>
    <div class="main">
        <img v-bind:src="cdn + 'logo1.png'" class="title-left-image"></img>
        <div class="title">
            <div class="soptions">
                <router-link to="/">
                    首页
                </router-link>
                <router-link to="/myself">
                    个人中心
                </router-link>
                <router-link to="/download">
                    下载中心
                </router-link>
                <router-link to="/getvip">
                    会员卡获取
                </router-link>
                <button class="connection_bt" @click="connection">
                    联系我们
                </button>
            </div>
            <router-link to="/login">
                {{ login_status }}
            </router-link>
        </div>

        <div class="contain">
            <router-view @fromlogin="dataFromLogin" />
        </div>

        <!-- <video class="video" ref="videoPlayer" src="../assets/video.mp4" width="600" loop autoplay  muted     ></video> -->

        <div v-if="showScrollBtn" class="kefu_xf1" @mouseover="kefu_wz_1" @mouseleave="kefu_wz1_1" @click="scrollToTop">
            <transition name="fade">
                <span v-if="kefu_wz_flag1" class="kefu_na1">显示导航栏</span>
            </transition>
        </div>

        <div class="kefu_xf" @mouseover="kefu_wz" @mouseleave="kefu_wz1" @click="kefu_xf">
            <transition name="fade">
                <span v-if="kefu_wz_flag" class="kefu_na">前台客服</span>
            </transition>
        </div>

        <transition name="fade">
            <div v-if="tot_flag" class="ToT">
                <button class="tot_close" @click="tot_close_tc"><i class="el-icon-close"></i></button>
                <span class="tot_title_sy">{{ tot_title }}</span>
                <span class="tot_context_sy">{{ tot_context }}</span>
                <button class="tot_button_bt" @click="kefu_fc">{{ tot_button }}</button>
            </div>
        </transition>

        <transition name="fade">
            <div v-if="tot_flag_1" class="ToT_1">
                <button class="tot_close" @click="tot_close_tc"><i class="el-icon-close"></i></button>
                <span class="tot_title_sy">{{ tot_title }}</span>
                <span class="tot_context_sy">{{ tot_context }}</span>
                <button class="tot_button_bt" @click="kefu_fc_1">{{ tot_button_1 }}</button>
                <!-- <button class="tot_button_bt_1" @click="kefu_fc_2">{{ tot_button_2 }}</button>
                <button class="tot_button_bt_2" @click="kefu_fc_3">{{ tot_button_3 }}</button> -->
            </div>
        </transition>

        <transition name="fade">
            <div v-if="tot1_flag" class="ToT1">
                <button class="tot1_close" @click="tot1_close_tc"><i class="el-icon-close"></i></button>
                <span class="tot1_title_sy">关于融视汇</span>
                <span
                    class="tot1_context_sy">融视汇科技传媒有限公司是隶属于爱奇艺、优酷、腾讯视频等各大影视平台的合作商,并专注于提供在线娱乐服务、视频采集、广告媒体投放。由多家平台授权打造，致力于更好的影视行业发展，且与广告联盟签订。与数百家广告公司联手，势必齐头并进，创新时代。</span>
                <span
                    class="tot1_context_sy1">公司于2023年创立在上海市。请教了各大影视行业翘楚，钻研数年倾力打造。通过聚合多个影视行业巨头APP、多个广告平台形成一个统一的新型广告模式。它具有多元化的广告渠道、高效的数据分析和个性化的投放体验等特点，结合现在众多影音APP会员份额高无法通过影视进行广告营销，广告利润无法最大化，广告商收益日渐下滑。公司凭借丰富的行业经验、专业的团队实力以及前瞻性的市场洞察，赢得了广大客户的信赖与好评。我们深知，只有不断创新和进步，才能在激烈的市场竞争中立于不败之地。因此，融视汇将继续秉承“创新、高效、精准”的理念，不断推动广告行业的变革与发展，为企业创造更大的价值。</span>
                <span
                    class="tot1_context_sy2">公司设有市场部、创意部、媒介部、客户部等多个部门，各部门之间紧密协作，共同推动公司业务的快速发展。公司能够运用最新的广告技术和工具，为客户提供高质量的广告作品。同时，公司还注重技术创新和研发，不断推出新的广告形式和技术手段，以满足客户日益增长的个性化需求。</span>
            </div>
        </transition>

        <transition name="fade">
            <div v-if="tl" class="taolun">
                <span class="taolun_title">加入qq群</span>
                <div class="taolun_context">
                    <span class="taolun_1">708314889</span>
                </div>
            </div>
        </transition>

        <div class="dibu">
            <div class="dibu1">
                <img class="dibu_tp" :src="cdn + 'logo.jpg'" />
            </div>
            <div class="dibu2">
                <span class="dibu_title">关联平台</span>
                <div class="dibu2_bt">
                    <div class="dibu2_bt_1">
                        <button class="dibu_button" @click="handle">爱奇艺</button>
                        <button class="dibu_button" @click="handle1">腾讯视频</button>
                        <button class="dibu_button" @click="handle2">搜狐视频</button>
                    </div>
                    <div class="dibu2_bt_1">
                        <button class="dibu_button" @click="handle3">优酷</button>
                        <button class="dibu_button" @click="handle4">西瓜视频</button>
                        <button class="dibu_button" @click="handle5">乐视</button>
                    </div>
                </div>
            </div>
            <div class="dibu3">
                <span class="dibu_title">服务声明</span>
                <div class="dibu3_bt">
                    <button class="dibu_button3" @click="handle6">讨论</button>
                    <button class="dibu_button3" @click="handle7">隐私协议</button>
                    <button class="dibu_button3" @click="handle8">客户端下载</button>
                </div>
            </div>

            <div class="dibu4">
                <span class="dibu_title">友情合作</span>
                <div class="dibu3_bt">
                    <button class="dibu_button3" @click="handle9">广告合作</button>
                    <button class="dibu_button3" @click="handle10">入住商家合作</button>
                    <button class="dibu_button3" @click="handle11">影视渠道合作</button>
                </div>
            </div>
            <div class="dibu5">
                <span class="dibu_title">探索融视汇</span>
                <div class="dibu3_bt">
                    <button class="dibu_button3" @click="handle12">加入我们</button>
                    <button class="dibu_button3" @click="handle13">关于我们</button>
                </div>
            </div>
            <div class="dibu7">
                <img class="dibu_tp" :src="cdn + 'logo.jpg'" />
            </div>
            <div class="dibu6">
                <span class="dibu6-da">营业执照 信息网络传播视听节目许可证 广播电视节目经营许可证 中国互联网诚信联盟联合辟谣平台 增值电信业务经营许可证 互联网信息服务算法备案信息
                    中国互联网举报中心</span>
                <span class="dibu6-xiao">Copyright © 2023-2024 融视汇 All Rights Reserved</span>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import global from '@/api/global/global.vue';
import Cookies from 'js-cookie'
import Myself from './gerenzhongxin/myself.vue';

export default {
    components: {
        Myself
    },
    name: 'index',
    data() {
        return {
            
            tot_button_3: '售后客服',
            tot_button_2: '财务客服',
            // tot_button_1: '代理客服',
            tot_button_1: '前台客服转接',
            tot_flag_1: false,
            tl: false,
            tot_flag: false,
            tot1_flag: false,
            tot_title: null,
            tot_context: null,
            tot_button: null,
            kefu_wz_flag: false,
            kefu_wz_flag1: false,
            login_status: '登录/注册',
            showScrollBtn: false,  // 控制按钮的显示与隐藏
            kefu_url: '',
            kefu_login_flag: false,
            kefu: {
                visiter_id: null,
                visiter_name: null,
                totalPoints: null,
                inviteCode: null,
                isActived: null,
                role: null,
                spentPoints: null,
                groupid: null,
                isActived: null,
                teamTotal: null
            },
            cdn: global.cdn
        }
    },
    created() {
        // 监听滚动事件
        window.addEventListener('scroll', this.checkScrollPosition);
    },
    destroyed() {
        // 在组件销毁时移除滚动事件监听
        window.removeEventListener('scroll', this.checkScrollPosition);
    },
    methods: {
        // 判断是否显示按钮
        checkScrollPosition() {
            // 获取当前页面的滚动位置
            if (window.scrollY > 0) {
                this.showScrollBtn = true;
            } else {
                this.showScrollBtn = false;
            }
        },
        // 滚动到顶部
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth', // 平滑滚动
            });
        },
        kefu_wz() {
            this.kefu_wz_flag = true
        },
        kefu_wz1() {
            this.kefu_wz_flag = false
        },

        kefu_wz_1() {
            this.kefu_wz_flag1 = true
        },
        kefu_wz1_1() {
            this.kefu_wz_flag1 = false
        },

        kefu_xf() {

            if (this.kefu_login_flag == true) {
                axios.get(global.url + '/user/my', {
                    headers: { Authorization: "Bearer " + Cookies.get("token") }
                }).then(response => {
                    if (response.data.code != 401) {
                        this.kefu.visiter_id = response.data.userId
                        this.kefu.visiter_name = response.data.userName
                        this.kefu.totalPoints = response.data.totalPoints / 10000
                        this.kefu.inviteCode = response.data.inviteCode
                        this.kefu.isActived = response.data.isActive
                        this.kefu.role = response.data.roleId
                        this.kefu.teamTotal = response.data.teamTotal
                        this.kefu.spentPoints = response.data.spentPoints / 10000
                        this.kefu.groupid = 6
                        if (this.kefu.role == 13) {
                            this.kefu.role = '总代理'
                        } else if (this.kefu.role == 12) {
                            this.kefu.role = '高级代理'
                        } else if (this.kefu.role == 11) {
                            this.kefu.role = '初级代理'
                        } else if (this.kefu.role == 10) {
                            if (this.kefu.isActived == 1) {
                                this.kefu.role = 'VIP'
                            } else {
                                this.kefu.role = '普通用户'
                            }
                        }

                        if (this.kefu.isActived == 1) {
                            this.kefu.isActived = '已激活'
                        } else {
                            this.kefu.isActived = '未激活'
                        }
                        window.open('https://kefu.rongshihuiys.com/index/index/home?avatar=&visiter_name=' + this.kefu.visiter_name + '&visiter_id=' + this.kefu.visiter_id + '&business_id=2&groupid=' + this.kefu.groupid + '&rsh_user=%7B%22userName%22%3A%22' + this.kefu.visiter_name + '%22%2C%22userld%22%3A%22' + this.kefu.visiter_id + '%22%2C%22role%22%3A%22' + this.kefu.role + '%22%2C%22isActive%22%3A%22' + this.kefu.isActived + '%22%2C%22totalPoints%22%3A%22' + this.kefu.totalPoints + '%22%2C%22spentPoints%22%3A%22' + this.kefu.spentPoints + '%22%2C%22totalTeam%22%3A%22' + this.kefu.teamTotal + '%22%2C%22inviteCode%22%3A%22' + this.kefu.inviteCode + '%22%7D', '_blank')
                    } else {
                        this.$message("登陆信息获取失败");
                    }

                }).catch(error => {
                    this.$message("错误！检查网络或联系客服" + error);
                })
            } else {
                window.open('https://kefu.rongshihuiys.com/index/index/home?avatar=&visiter_name=&visiter_id=&business_id=2&groupid=6', '_blank')
            }


        },

        kefu_fc() {
            window.open(this.kefu_url, '_blank')
            this.kefu_url = '';
        },
        kefu_fc_1() {
            this.kefu.groupid = 6
            this.kefu_url = 'https://kefu.rongshihuiys.com/index/index/home?avatar=&visiter_name=' + this.kefu.visiter_name + '&visiter_id=' + this.kefu.visiter_id + '&business_id=2&groupid=' + this.kefu.groupid + '&rsh_user=%7B%22userName%22%3A%22' + this.kefu.visiter_name + '%22%2C%22userld%22%3A%22' + this.kefu.visiter_id + '%22%2C%22role%22%3A%22' + this.kefu.role + '%22%2C%22isActive%22%3A%22' + this.kefu.isActived + '%22%2C%22totalPoints%22%3A%22' + this.kefu.totalPoints + '%22%2C%22spentPoints%22%3A%22' + this.kefu.spentPoints + '%22%2C%22totalTeam%22%3A%22' + this.kefu.teamTotal + '%22%2C%22inviteCode%22%3A%22' + this.kefu.inviteCode + '%22%7D'
            window.open(this.kefu_url, '_blank')
            this.kefu_url = '';
        },
        // kefu_fc_2() {
        //     this.kefu.groupid = 6
        //     this.kefu_url = 'https://kefu.rongshihuiys.com/index/index/home?avatar=&visiter_name=' + this.kefu.visiter_name + '&visiter_id=' + this.kefu.visiter_id + '&business_id=2&groupid=' + this.kefu.groupid + '&rsh_user=%7B%22userName%22%3A%22' + this.kefu.visiter_name + '%22%2C%22userld%22%3A%22' + this.kefu.visiter_id + '%22%2C%22role%22%3A%22' + this.kefu.role + '%22%2C%22isActive%22%3A%22' + this.kefu.isActived + '%22%2C%22totalPoints%22%3A%22' + this.kefu.totalPoints + '%22%2C%22spentPoints%22%3A%22' + this.kefu.spentPoints + '%22%2C%22totalTeam%22%3A%22' + this.kefu.teamTotal + '%22%2C%22inviteCode%22%3A%22' + this.kefu.inviteCode + '%22%7D'
        //     window.open(this.kefu_url, '_blank')
        //     this.kefu_url = '';
        // },
        // kefu_fc_3() {
        //     this.kefu.groupid = 6
        //     this.kefu_url = 'https://kefu.rongshihuiys.com/index/index/home?avatar=&visiter_name=' + this.kefu.visiter_name + '&visiter_id=' + this.kefu.visiter_id + '&business_id=2&groupid=' + this.kefu.groupid + '&rsh_user=%7B%22userName%22%3A%22' + this.kefu.visiter_name + '%22%2C%22userld%22%3A%22' + this.kefu.visiter_id + '%22%2C%22role%22%3A%22' + this.kefu.role + '%22%2C%22isActive%22%3A%22' + this.kefu.isActived + '%22%2C%22totalPoints%22%3A%22' + this.kefu.totalPoints + '%22%2C%22spentPoints%22%3A%22' + this.kefu.spentPoints + '%22%2C%22totalTeam%22%3A%22' + this.kefu.teamTotal + '%22%2C%22inviteCode%22%3A%22' + this.kefu.inviteCode + '%22%7D'
        //     window.open(this.kefu_url, '_blank')
        //     this.kefu_url = '';
        // },
        
        dataFromLogin(data) {
            if (data) {
                this.login_status = Cookies.get("name")
                this.kefu_login_flag = true;
            } else {
                this.login_status = "登录/注册"
                this.kefu_login_flag = false;
            }
        },
        tot_close_tc() {
            this.tot_flag = false
            this.tot_flag_1 = false
        },
        tot1_close_tc() {
            this.tot1_flag = false
        },
        connection() {
            this.tot_title = '联系我们'
            this.tot_context = '点击下方前往联系我们'
            this.tot_button = '跳转联系我们'

            if (this.tot_flag || this.tot_flag_1) {
                this.tot_flag = false
                this.tot_flag_1 = false
                return
            }

            if (this.kefu_login_flag == true) {
                axios.get(global.url + '/user/my', {
                    headers: { Authorization: "Bearer " + Cookies.get("token") }
                }).then(response => {
                    if (response.data.code != 401) {
                        this.kefu.visiter_id = response.data.userId
                        this.kefu.visiter_name = response.data.userName
                        this.kefu.totalPoints = response.data.totalPoints / 10000
                        this.kefu.inviteCode = response.data.inviteCode
                        this.kefu.isActived = response.data.isActive
                        this.kefu.role = response.data.roleId
                        this.kefu.teamTotal = response.data.teamTotal
                        this.kefu.spentPoints = response.data.spentPoints / 10000

                        if (this.kefu.role == 13) {
                            this.kefu.role = '总代理'
                        } else if (this.kefu.role == 12) {
                            this.kefu.role = '高级代理'
                        } else if (this.kefu.role == 11) {
                            this.kefu.role = '初级代理'
                        } else if (this.kefu.role == 10) {
                            if (this.kefu.isActived == 1) {
                                this.kefu.role = 'VIP'
                            } else {
                                this.kefu.role = '普通用户'
                            }
                        }

                        if (this.kefu.isActived == 1) {
                            this.kefu.isActived = '已激活'
                        } else {
                            this.kefu.isActived = '未激活'
                        }
                        this.tot_flag_1 = true
                    } else {
                        this.$message("登陆信息获取失败");
                    }
                }).catch(error => {
                    this.loading1 = false;
                    this.$message("错误！检查网络或联系客服" + error);
                })
            } else {
                this.kefu_url = 'https://kefu.rongshihuiys.com/index/index/home?avatar=&visiter_name=&visiter_id=&business_id=2&groupid=6'
                this.tot_flag = true
            }

        },
        handle() {
            window.open('https://www.iqiyi.com/', '_blank')
        },
        handle1() {
            window.open('https://v.qq.com/', '_blank')
        },
        handle2() {
            window.open('https://tv.sohu.com/', '_blank')
        },
        handle3() {
            window.open('https://www.youku.com/', '_blank')
        },
        handle4() {
            window.open('https://www.ixigua.com/', '_blank')
        },
        handle5() {
            window.open('https://www.le.com/', '_blank')
        },
        handle6() {
            this.tl = !this.tl;
            setTimeout(() => {
                this.tl = false;
            }, 5000);
        },
        handle7() {
            this.$router.push({ path: '/privacy' }).catch(err => { err })
        },
        handle8() {
            this.$router.push({ path: '/download' }).catch(err => { err })
        },
        handle9() {
            this.tot_title = '广告合作'
            this.tot_context = '点击下方前往广告合作'
            this.tot_button = '跳转广告合作'
            if (this.tot_flag) {
                this.tot_flag = false
                return
            }

            if (this.kefu_login_flag == true) {
                axios.get(global.url + '/user/my', {
                    headers: { Authorization: "Bearer " + Cookies.get("token") }
                }).then(response => {
                    if (response.data.code != 401) {
                        this.kefu.visiter_id = response.data.userId
                        this.kefu.visiter_name = response.data.userName
                        this.kefu.totalPoints = response.data.totalPoints / 10000
                        this.kefu.inviteCode = response.data.inviteCode
                        this.kefu.isActived = response.data.isActive
                        this.kefu.role = response.data.roleId
                        this.kefu.teamTotal = response.data.teamTotal
                        this.kefu.spentPoints = response.data.spentPoints / 10000
                        this.kefu.groupid = 6
                        if (this.kefu.role == 13) {
                            this.kefu.role = '总代理'
                        } else if (this.kefu.role == 12) {
                            this.kefu.role = '高级代理'
                        } else if (this.kefu.role == 11) {
                            this.kefu.role = '初级代理'
                        } else if (this.kefu.role == 10) {
                            if (this.kefu.isActived == 1) {
                                this.kefu.role = 'VIP'
                            } else {
                                this.kefu.role = '普通用户'
                            }
                        }

                        if (this.kefu.isActived == 1) {
                            this.kefu.isActived = '已激活'
                        } else {
                            this.kefu.isActived = '未激活'
                        }

                        this.kefu_url = 'https://kefu.rongshihuiys.com/index/index/home?avatar=&visiter_name=' + this.kefu.visiter_name + '&visiter_id=' + this.kefu.visiter_id + '&business_id=2&groupid=' + this.kefu.groupid + '&rsh_user=%7B%22userName%22%3A%22' + this.kefu.visiter_name + '%22%2C%22userld%22%3A%22' + this.kefu.visiter_id + '%22%2C%22role%22%3A%22' + this.kefu.role + '%22%2C%22isActive%22%3A%22' + this.kefu.isActived + '%22%2C%22totalPoints%22%3A%22' + this.kefu.totalPoints + '%22%2C%22spentPoints%22%3A%22' + this.kefu.spentPoints + '%22%2C%22totalTeam%22%3A%22' + this.kefu.teamTotal + '%22%2C%22inviteCode%22%3A%22' + this.kefu.inviteCode + '%22%7D'
                        this.tot_flag = true
                    } else {
                        this.$message("登陆信息获取失败");
                    }
                }).catch(error => {
                    this.loading1 = false;
                    this.$message("错误！检查网络或联系客服" + error);
                })
            } else {
                this.kefu_url = 'https://kefu.rongshihuiys.com/index/index/home?avatar=&visiter_name=&visiter_id=&business_id=2&groupid=6'
                this.tot_flag = true
            }
        },
        handle10() {
            this.tot_title = '入住商家合作'
            this.tot_context = '点击下方前往入住商家合作'
            this.tot_button = '跳转入住商家合作'
            if (this.tot_flag) {
                this.tot_flag = false
                return
            }

            if (this.kefu_login_flag == true) {
                axios.get(global.url + '/user/my', {
                    headers: { Authorization: "Bearer " + Cookies.get("token") }
                }).then(response => {
                    if (response.data.code != 401) {
                        this.kefu.visiter_id = response.data.userId
                        this.kefu.visiter_name = response.data.userName
                        this.kefu.totalPoints = response.data.totalPoints / 10000
                        this.kefu.inviteCode = response.data.inviteCode
                        this.kefu.isActived = response.data.isActive
                        this.kefu.role = response.data.roleId
                        this.kefu.teamTotal = response.data.teamTotal
                        this.kefu.spentPoints = response.data.spentPoints / 10000
                        this.kefu.groupid = 6
                        if (this.kefu.role == 13) {
                            this.kefu.role = '总代理'
                        } else if (this.kefu.role == 12) {
                            this.kefu.role = '高级代理'
                        } else if (this.kefu.role == 11) {
                            this.kefu.role = '初级代理'
                        } else if (this.kefu.role == 10) {
                            if (this.kefu.isActived == 1) {
                                this.kefu.role = 'VIP'
                            } else {
                                this.kefu.role = '普通用户'
                            }
                        }

                        if (this.kefu.isActived == 1) {
                            this.kefu.isActived = '已激活'
                        } else {
                            this.kefu.isActived = '未激活'
                        }

                        this.kefu_url = 'https://kefu.rongshihuiys.com/index/index/home?avatar=&visiter_name=' + this.kefu.visiter_name + '&visiter_id=' + this.kefu.visiter_id + '&business_id=2&groupid=' + this.kefu.groupid + '&rsh_user=%7B%22userName%22%3A%22' + this.kefu.visiter_name + '%22%2C%22userld%22%3A%22' + this.kefu.visiter_id + '%22%2C%22role%22%3A%22' + this.kefu.role + '%22%2C%22isActive%22%3A%22' + this.kefu.isActived + '%22%2C%22totalPoints%22%3A%22' + this.kefu.totalPoints + '%22%2C%22spentPoints%22%3A%22' + this.kefu.spentPoints + '%22%2C%22totalTeam%22%3A%22' + this.kefu.teamTotal + '%22%2C%22inviteCode%22%3A%22' + this.kefu.inviteCode + '%22%7D'
                        this.tot_flag = true
                    } else {
                        this.$message("登陆信息获取失败");
                    }
                }).catch(error => {
                    this.loading1 = false;
                    this.$message("错误！检查网络或联系客服" + error);
                })
            } else {
                this.kefu_url = 'https://kefu.rongshihuiys.com/index/index/home?avatar=&visiter_name=&visiter_id=&business_id=2&groupid=6'
                this.tot_flag = true
            }
        },
        handle11() {
            this.tot_title = '影视渠道合作'
            this.tot_context = '点击下方前往影视渠道合作'
            this.tot_button = '跳转影视渠道合作'
            if (this.tot_flag) {
                this.tot_flag = false
                return
            }

            if (this.kefu_login_flag == true) {
                axios.get(global.url + '/user/my', {
                    headers: { Authorization: "Bearer " + Cookies.get("token") }
                }).then(response => {
                    if (response.data.code != 401) {
                        this.kefu.visiter_id = response.data.userId
                        this.kefu.visiter_name = response.data.userName
                        this.kefu.totalPoints = response.data.totalPoints / 10000
                        this.kefu.inviteCode = response.data.inviteCode
                        this.kefu.isActived = response.data.isActive
                        this.kefu.role = response.data.roleId
                        this.kefu.teamTotal = response.data.teamTotal
                        this.kefu.spentPoints = response.data.spentPoints / 10000
                        this.kefu.groupid = 6
                        if (this.kefu.role == 13) {
                            this.kefu.role = '总代理'
                        } else if (this.kefu.role == 12) {
                            this.kefu.role = '高级代理'
                        } else if (this.kefu.role == 11) {
                            this.kefu.role = '初级代理'
                        } else if (this.kefu.role == 10) {
                            if (this.kefu.isActived == 1) {
                                this.kefu.role = 'VIP'
                            } else {
                                this.kefu.role = '普通用户'
                            }
                        }

                        if (this.kefu.isActived == 1) {
                            this.kefu.isActived = '已激活'
                        } else {
                            this.kefu.isActived = '未激活'
                        }

                        this.kefu_url = 'https://kefu.rongshihuiys.com/index/index/home?avatar=&visiter_name=' + this.kefu.visiter_name + '&visiter_id=' + this.kefu.visiter_id + '&business_id=2&groupid=' + this.kefu.groupid + '&rsh_user=%7B%22userName%22%3A%22' + this.kefu.visiter_name + '%22%2C%22userld%22%3A%22' + this.kefu.visiter_id + '%22%2C%22role%22%3A%22' + this.kefu.role + '%22%2C%22isActive%22%3A%22' + this.kefu.isActived + '%22%2C%22totalPoints%22%3A%22' + this.kefu.totalPoints + '%22%2C%22spentPoints%22%3A%22' + this.kefu.spentPoints + '%22%2C%22totalTeam%22%3A%22' + this.kefu.teamTotal + '%22%2C%22inviteCode%22%3A%22' + this.kefu.inviteCode + '%22%7D'
                        this.tot_flag = true
                    } else {
                        this.$message("登陆信息获取失败");
                    }
                }).catch(error => {
                    this.loading1 = false;
                    this.$message("错误！检查网络或联系客服" + error);
                })
            } else {
                this.kefu_url = 'https://kefu.rongshihuiys.com/index/index/home?avatar=&visiter_name=&visiter_id=&business_id=2&groupid=6'
                this.tot_flag = true
            }
        },
        handle12() {
            this.tot_title = '探索融视汇'
            this.tot_context = '点击下方前往加入我们'
            this.tot_button = '跳转加入我们'
            if (this.tot_flag) {
                this.tot_flag = false
                return
            }

            if (this.kefu_login_flag == true) {
                axios.get(global.url + '/user/my', {
                    headers: { Authorization: "Bearer " + Cookies.get("token") }
                }).then(response => {
                    if (response.data.code != 401) {
                        this.kefu.visiter_id = response.data.userId
                        this.kefu.visiter_name = response.data.userName
                        this.kefu.totalPoints = response.data.totalPoints / 10000
                        this.kefu.inviteCode = response.data.inviteCode
                        this.kefu.isActived = response.data.isActive
                        this.kefu.role = response.data.roleId
                        this.kefu.teamTotal = response.data.teamTotal
                        this.kefu.spentPoints = response.data.spentPoints / 10000
                        this.kefu.groupid = 6
                        if (this.kefu.role == 13) {
                            this.kefu.role = '总代理'
                        } else if (this.kefu.role == 12) {
                            this.kefu.role = '高级代理'
                        } else if (this.kefu.role == 11) {
                            this.kefu.role = '初级代理'
                        } else if (this.kefu.role == 10) {
                            if (this.kefu.isActived == 1) {
                                this.kefu.role = 'VIP'
                            } else {
                                this.kefu.role = '普通用户'
                            }
                        }

                        if (this.kefu.isActived == 1) {
                            this.kefu.isActived = '已激活'
                        } else {
                            this.kefu.isActived = '未激活'
                        }

                        this.kefu_url = 'https://kefu.rongshihuiys.com/index/index/home?avatar=&visiter_name=' + this.kefu.visiter_name + '&visiter_id=' + this.kefu.visiter_id + '&business_id=2&groupid=' + this.kefu.groupid + '&rsh_user=%7B%22userName%22%3A%22' + this.kefu.visiter_name + '%22%2C%22userld%22%3A%22' + this.kefu.visiter_id + '%22%2C%22role%22%3A%22' + this.kefu.role + '%22%2C%22isActive%22%3A%22' + this.kefu.isActived + '%22%2C%22totalPoints%22%3A%22' + this.kefu.totalPoints + '%22%2C%22spentPoints%22%3A%22' + this.kefu.spentPoints + '%22%2C%22totalTeam%22%3A%22' + this.kefu.teamTotal + '%22%2C%22inviteCode%22%3A%22' + this.kefu.inviteCode + '%22%7D'
                        this.tot_flag = true
                    } else {
                        this.$message("登陆信息获取失败");
                    }
                }).catch(error => {
                    this.loading1 = false;
                    this.$message("错误！检查网络或联系客服" + error);
                })
            } else {
                this.kefu_url = 'https://kefu.rongshihuiys.com/index/index/home?avatar=&visiter_name=&visiter_id=&business_id=2&groupid=6'
                this.tot_flag = true
            }
        },
        handle13() {
            this.tot1_flag = !this.tot1_flag
        },
    }
}
</script>

<style lang="scss" scoped>
.main {
    position: relative;
    width: 100vw;
    aspect-ratio: 1.8;
}

//————————————————————————————————————————————————————————导航栏——————————————————————————————————————————————————————————————
.title {
    height: 4vw;
    width: 100vw;
    background-color: black;
    display: flex;
    justify-content: space-evenly;
}

.title-left-image {
    position: absolute;
    left: 5vw;
    top: 0.2vw;
    width: 7vw;
    height: 6vw;
    border-radius: 1vw;
    background-color: transparent;
    z-index: 9;
}

.soptions {
    margin-left: 10vw;
    width: 60vw;
    height: 4vw;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

a {
    width: 10vw;
    height: 4vw;
    color: white;
    font-size: 1.3vw;
    font-family: ziti3;
    margin: 0px 0px;
    text-decoration: none;
    line-height: 4vw;
    text-align: center;
}

a:hover {
    background-color: #363434;
}

.connection_bt {
    width: 10vw;
    height: 4vw;
    color: white;
    font-size: 1.3vw;
    font-family: ziti3;
    margin: 0px 0px;
    text-decoration: none;
    line-height: 4vw;
    text-align: center;
    border: none;
    background-color: black;
}

.connection_bt:hover {
    background-color: #363434;
}

.login {
    position: absolute;
    top: 20vw;
    left: 35vw;
    width: 30vw;
    height: 25vw;
    background-color: blue;
    z-index: 10;
}


.contain {
    width: 100vw;
    height: 93vh;
    flex: 1;
    overflow: auto;
    box-sizing: border-box;

    scrollbar-width: none;
    -ms-overflow-style: none;

    background-image: url("https://rongshihui.cdn2.cc/files/assets/login/beijing2.jpg");
    background-position: center;
    background-size: 100vw 93vh;
}

.video{
    position: absolute;
    top: 15vw;
    left: 30vw;

    background-color: white;
    z-index: 8;
}

.kefu_xf1 {
    position: absolute;
    background-image: url('https://rongshihui.cdn2.cc/files/assets/daohanglan.png');
    background-position: center;
    background-size: cover;
    top: 20vh;
    right: 0.5vw;
    background-color: transparent;
    width: 5vw;
    height: 5vw;
    z-index: 11;
    animation: floating2 3s ease-in-out infinite;
    /* 持续的浮动动画 */
}

.kefu_xf {
    position: absolute;
    background-image: url('https://rongshihui.cdn2.cc/files/assets/kefu3.png');
    background-position: center;
    background-size: cover;
    top: 26vw;
    right: 0.5vw;
    background-color: transparent;
    width: 5vw;
    height: 5vw;
    z-index: 11;
    animation: floating2 3s ease-in-out infinite;
    /* 持续的浮动动画 */
}



@keyframes floating2 {
    0% {
        transform: translateY(0);
    }

    25% {
        transform: translateY(-8px);
    }

    75% {
        transform: translateY(8px);
    }

    100% {
        transform: translateY(0);
    }
}

.kefu_na {
    position: absolute;
    width: 5vw;
    text-align: center;
    color: white;
    font-size: 1.2vw;
    font-family: ziti3;
    top: 5vw;
    transition: opacity 0.5s ease;
}

.kefu_na1 {
    position: absolute;
    margin-left: -0.5vw;
    width: 6vw;
    text-align: center;
    color: white;
    font-size: 1.2vw;
    font-family: ziti3;
    top: 5vw;
    transition: opacity 0.5s ease;
}

.kefu_xf:hover {
    animation: hoverEffect1 0.3s ease-out forwards;
    /* 鼠标悬停时的动画 */
}

@keyframes hoverEffect1 {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
        /* 放大效果 */
    }

    100% {
        transform: scale(1);
        /* 恢复原始大小 */
    }
}

//————————————————————————————————————————————————提示框————————————————————————————————————————————————————————————————————————
.ToT {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(6px);
    top: 22vw;
    width: 30vw;
    height: 15vw;
    left: 35vw;
    border-radius: 1vw;
    z-index: 10;
    transition: opacity 0.5s ease;
}

.ToT_1 {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(6px);
    // top: 15vw;
    top: 22vw;
    width: 30vw;
    // height: 30vw;
    height: 15vw;
    left: 35vw;
    border-radius: 1vw;
    z-index: 10;
    transition: opacity 0.5s ease;
}

.tot_close {
    position: absolute;
    top: 0.5vw;
    right: 1vw;
    text-align: center;
    background-color: transparent;
    color: white;
    border: none;
    font-size: 1.2vw;
    z-index: 10;
}

.tot_title_sy {
    position: absolute;
    color: white;
    top: 1vw;
    width: 30vw;
    text-align: center;
    font-size: 2vw;
    font-family: ziti3;
}

.tot_context_sy {
    position: absolute;
    color: white;
    top: 5vw;
    width: 30vw;
    text-align: center;
    font-size: 1.5vw;
    font-family: ziti3;
}

.tot_button_bt {
    position: absolute;
    top: 8vw;
    left: 4.7vw;
    width: 20.5vw;
    height: 5vw;
    border-radius: 1vw;
    background-color: #53aaa7;
    border: none;
    color: white;
    text-align: center;
    font-size: 1.5vw;
    font-family: ziti3;
}

.tot_button_bt:hover {
    background-color: grey;
}

.tot_button_bt_1 {
    position: absolute;
    top: 15vw;
    left: 4.7vw;
    width: 20.5vw;
    height: 5vw;
    border-radius: 1vw;
    background-color: #53aaa7;
    border: none;
    color: white;
    text-align: center;
    font-size: 1.5vw;
    font-family: ziti3;
}

.tot_button_bt_1:hover {
    background-color: grey;
}

.tot_button_bt_2 {
    position: absolute;
    top: 22vw;
    left: 4.7vw;
    width: 20.5vw;
    height: 5vw;
    border-radius: 1vw;
    background-color: #53aaa7;
    border: none;
    color: white;
    text-align: center;
    font-size: 1.5vw;
    font-family: ziti3;
}

.tot_button_bt_2:hover {
    background-color: grey;
}



.ToT1 {
    position: absolute;
    background-color: rgba(128, 128, 128, 0.8);
    backdrop-filter: blur(6px);
    top: 10vw;
    width: 40vw;
    height: 45vw;
    left: 30vw;
    border-radius: 1vw;
    z-index: 10;
    transition: opacity 0.5s ease;
}

.tot1_close {
    position: absolute;
    top: 0.5vw;
    right: 0.5vw;
    text-align: center;
    background-color: transparent;
    border: none;
    font-size: 1.5vw;
    z-index: 10;
}

.tot1_title_sy {
    position: absolute;
    top: 2vw;
    font-size: 2vw;
    font-family: ziti3;
    left: 15vw;
}

.tot1_context_sy {
    position: absolute;
    text-indent: 2em;
    top: 5vw;
    left: 2vw;
    font-size: 1.4vw;
    font-family: ziti3;
    width: 36vw;
}

.tot1_context_sy1 {
    position: absolute;
    text-indent: 2em;
    top: 13vw;
    left: 2vw;
    font-size: 1.4vw;
    font-family: ziti3;
    width: 36vw;
}

.tot1_context_sy2 {
    position: absolute;
    text-indent: 2em;
    top: 33vw;
    left: 2vw;
    font-size: 1.4vw;
    font-family: ziti3;
    width: 36vw;
}

//————————————————————————————————————————————————————————底部——————————————————————————————————————————————————————————————
.dibu {
    position: relative;
    width: 100vw;
    height: 9vw;
    background-color: black;
}

.dibu1 {
    width: 10vw;
    height: 6vw;
    position: absolute;
    top: 5%;
    left: 2vw;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: black;
}

.dibu2 {
    width: 10vw;
    height: 6.5vw;
    position: absolute;
    top: 0.2vw;
    right: 75vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.dibu_title {
    width: 10vw;
    height: 1.5vw;
    font-size: 1.4vw;
    font-family: ziti3;
    text-align: center;
    color: grey;
}

.dibu2_bt {
    width: 10vw;
    height: 5vw;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.dibu2_bt_1 {
    width: 5vw;
    height: 4vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.dibu_button {
    width: 5vw;
    height: 1vw;
    font-size: 1vw;
    font-family: ziti3;
    color: grey;
    background-color: black;
    border: none;
}

.dibu3 {
    width: 10vw;
    height: 6.5vw;
    position: absolute;
    top: 0.2vw;
    right: 55vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.taolun {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(6px);
    line-height: 6vw;
    top: 22vw;
    text-align: center;
    width: 30vw;
    height: 15vw;
    left: 35vw;
    border-radius: 1vw;
    z-index: 10;
    transition: opacity 0.5s ease;
}

/* 定义过渡效果 */
.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.taolun_title {
    width: 30vw;
    font-size: 2vw;
    font-family: ziti3;
    color: #53aaa7;
    line-height: 2vw;
}

.taolun_context {
    width: 30vw;
    color: #53aaa7;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-size: 1.5vw;
    font-family: ziti3;
    line-height: 5vw;
}

.dibu3_bt {
    width: 100%;
    height: 5vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.dibu_button3 {
    width: 100%;
    height: 1.5vw;
    font-size: 1vw;
    font-family: ziti3;
    color: grey;
    background-color: black;
    border: none;
}

.dibu4 {
    width: 10vw;
    height: 6.5vw;
    position: absolute;
    top: 0.2vw;
    right: 35vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.dibu5 {
    width: 10vw;
    height: 6.5vw;
    position: absolute;
    top: 0.2vw;
    right: 15vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.dibu6 {
    position: absolute;
    width: 70vw;
    height: 1.9vw;
    top: 6.8vw;
    right: 15vw;
    text-align: center;
    color: grey;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.dibu6-da {
    width: 100%;
    font-size: 0.7vw;
}

.dibu6-xiao {
    width: 100%;
    font-size: 0.5vw;
}

.dibu7 {
    width: 10vw;
    height: 6vw;
    position: absolute;
    top: 5%;
    right: 2vw;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: black;
}
</style>