<template>
    <div class="main">
        <div class="window">
            <div class="k1">
                <span class="da">融视汇客户端</span>
                <span class="xiao">畅享4K视频杜比音效</span>
                <img class="bijiben" :src="cdn+'xiazaizhongxin/bijiben.png'" />
            </div>
            <div class="k2">
                <span class="k2_da_1">下 载 安 装</span>
                <span class="k2_da_2">即 享 收 入</span>
                <span class="k2_xiao_1">Download installation</span>
                <span class="k2_xiao_2">Enjoy revenue</span>
                <div class="xiazai">
                    <div class="mac">
                        <img class="icon" :src="cdn+'xiazaizhongxin/mac.png'" />
                        <span class="title">电脑端MAC安装包</span>
                        <button class="button" @click="mac_download">立即下载</button>
                    </div>
                    <div class="win">
                        <img class="icon1" :src="cdn+'xiazaizhongxin/win.png'" />
                        <span class="title">电脑端Windows安装包</span>
                        <button class="button" @click="win_download">立即下载</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import global from '@/api/global/global.vue';
import Cookies from 'js-cookie'

export default {
    name: 'download',
    data() {
        return {
            timer: '',
            s: null,
            timer_flag: false,
            cdn: global.cdn
        }
    },
    methods: {
        mac_download() {
            this.$message("加班开发中")
        },
        s1() {
            if (this.s > 0) {
                this.s--;
            } else {
                clearInterval(this.timer)
                this.timer_flag = false
            }
        },
        win_download() {
            if (!this.timer_flag) {
                const downloadUrl = 'https://rongshihui.cdn2.cc/files/client/%E8%9E%8D%E8%A7%86%E6%B1%87%E5%AE%89%E8%A3%85%E5%8C%85.exe';  // 下载链接
                const a = document.createElement('a');  // 创建一个 <a> 标签
                a.href = downloadUrl;  // 设置下载链接
                a.download = '';  // 设置为下载文件，空字符串表示使用文件原始名称
                a.style.display = 'none';  // 隐藏这个 <a> 标签
                document.body.appendChild(a);  // 将 <a> 标签添加到页面上
                a.click();  // 模拟点击事件，触发下载
                document.body.removeChild(a);  // 下载完成后移除 <a> 标签
                this.timer_flag = true
                this.s = Math.floor(Math.random() * 50) + 10;
                this.timer = setInterval(this.s1,1000)
            } else {
                this.$message("由于下载服务器繁忙，" + this.s + "秒后方可下载")
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.main {
    position: relative;
    width: 100vw;
}

.window {
    position: absolute;
    top: 4vw;
    left: 10vw;
    width: 80vw;
    background-color: rgba(0, 0, 0, 0.39);
    backdrop-filter: blur(6px);
    border-radius: 2vw;
    height: 49vw;
}

.k1 {
    position: relative;
    width: 100vw;
    height: 40.5vw;
    z-index: 5;
}

.da {
    position: absolute;
    width: 80vw;
    position: absolute;
    top: 2vw;
    right: 20vw;
    font-size: 6vw;
    font-family: ziti1;
    text-align: center;
    color: white;

}

.xiao {
    width: 80vw;
    position: absolute;
    top: 10vw;
    right: 20vw;
    font-size: 2vw;
    font-family: ziti6;
    text-align: center;
    color: white;
}

.bijiben {
    position: absolute;
    width: 45vw;
    height: 25vw;
    top: 17vw;
    right: 36vw;
}

.k2 {
    position: absolute;
    top: 5vw;
    width: 100vw;
    height: 40.5vw;
}

.k2_da_1 {
    position: absolute;
    width: 20vw;
    top: 6vw;
    left: 3.7vw;
    color: white;
    font-size: 3vw;
    font-family: ziti3;
    text-align: center;
}

.k2_da_2 {
    position: absolute;
    width: 20vw;
    top: 6vw;
    right: 23.5vw;
    color: white;
    font-size: 3vw;
    font-family: ziti3;
    text-align: center;
}

.k2_xiao_1 {
    position: absolute;
    width: 20vw;
    top: 10vw;
    left: 3.5vw;
    color: white;
    font-size: 1vw;
    font-family: ziti3;
    text-align: center;
}

.k2_xiao_2 {
    position: absolute;
    width: 20vw;
    top: 10vw;
    right: 23.5vw;
    color: white;
    font-size: 1vw;
    font-family: ziti3;
    text-align: center;
}

.xiazai {
    position: absolute;
    width: 70vw;
    height: 25vw;
    top: 13vw;
    left: 5vw;

}

.mac {
    position: absolute;
    height: 25vw;
    width: 17.5vw;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 2vw;
}

.win {
    position: absolute;
    right: 0vw;
    height: 25vw;
    width: 17.5vw;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 2vw;
}

.icon {
    position: absolute;
    left: 1.75vw;
    width: 14vw;
    height: 15vw;
}

.icon1 {
    position: absolute;
    left: 1.7vw;
    top: 2.4vw;
    width: 14.9vw;
    height: 10.2vw;
}

.title {
    position: absolute;
    top: 16vw;
    left: 00.75vw;
    width: 16vw;
    height: 2.5vw;
    font-size: 1.5vw;
    font-family: ziti3;
    color: white;
    text-align: center;
}

.button {
    position: absolute;
    top: 20vw;
    left: 3.5vw;
    width: 10.5vw;
    font-size: 1.5vw;
    font-family: ziti3;
    height: 2.5vw;
    text-align: center;
    border: none;
    border-radius: 1vw;
    color: white;
    background-color: #53aaa7;
    z-index: 10;
}

.button:hover {
    background-color: grey;
}
</style>