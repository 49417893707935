<template>
    <div class="main">
        <div class="block">
            <el-carousel height="40vw">
                <el-carousel-item interval="7000" v-for="item in lbt" :key="item">
                    <div class="lunbo" :style="{ backgroundImage: `url(${item.imgSrc})` }"></div>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="biaoti_4">
            <div class="xinxi_tubiao_4">
                <span class="zhu">新形态的媒体力量</span>
                <span class="ci">1v1专属服务专项</span>
                <span class="ci">对接大量闲置资源客户</span>
            </div>
            <div class="zhuangzai">
                <div class="rongqi_zz">
                    <button style="background-color: transparent;border: none;" @click="b4_2">
                        <img class="shake-on-hover" :src="cdn+'shouye/tx.png'" />
                    </button>
                    <span class="wenzi_zz">腾讯</span>
                </div>
                <div class="rongqi_zz">
                    <button style="background-color: transparent;border: none;" @click="b4_3">
                        <img class="shake-on-hover" :src="cdn+'shouye/yk.png'" />
                    </button>
                    <span class="wenzi_zz">优酷</span>
                </div>
                <div class="rongqi_zz">
                    <button style="background-color: transparent;border: none;" @click="b4_1">
                        <img class="shake-on-hover" :src="cdn+'shouye/aqy.png'" />
                    </button>
                    <span class="wenzi_zz">爱奇艺</span>
                </div>
                <div class="rongqi_zz">
                    <button style="background-color: transparent;border: none;" @click="b4_4">
                        <img class="shake-on-hover" :src="cdn+'shouye/xg.png'" />
                    </button>
                    <span class="wenzi_zz">西瓜</span>
                </div>
                <div class="rongqi_zz">
                    <button style="background-color: transparent;border: none;" @click="b4_1">
                        <img class="shake-on-hover1" :src="cdn+'shouye/sh.png'" />
                    </button>
                    <span class="wenzi_zz1">搜狐</span>
                </div>
            </div>
        </div>
        <div class="changbeijing">
            <div class="beijing_1">
                <div class="tubiao_3_zz">
                    <img class="tubiao_3" :src="cdn+'shouye/44.png'" />
                    <span class="tubiao_3_wz">闲置资源赚收益</span>
                </div>
                <div class="tubiao_3_zz">
                    <img class="tubiao_3" :src="cdn+'shouye/22.png'" />
                    <span class="tubiao_3_wz">操作便捷 收益稳定</span>
                </div>
                <div class="tubiao_3_zz">
                    <img class="tubiao_3" :src="cdn+'shouye/11.png'" />
                    <span class="tubiao_3_wz">智能共享计算平台</span>
                </div>

            </div>
            <div class="bj_1_zz">
                <span class="beijing_1_wz">顺应趋势 抓住机遇 已成为当今社会的一种常态</span>
                <span class="beijing_2_wz">持续学习 提升个人能力 是成功的关键</span>
                <span class="beijing_3_wz">公司与您携手并进 共同持续发展 终将脱颖而出</span>
            </div>
            <div class="beijing_2">
                <div class="tupian_3_zz">
                    <div class="tupian_1_rq" @mouseover="onMouseOver" @mouseleave="onMouseLeave">
                        <transition name="fade">
                            <div v-if="!isMouseOver" class="tupian_wz_zz">
                                <span class="tupian_wz">广告设计与策划</span>
                                <span class="tupian_wz_en">Advertising planing and design</span>
                            </div>
                        </transition>
                        <transition name="fade">
                            <div v-if="isMouseOver" class="tupian_wz_zz_js">
                                <span class="tupian_wz_js">
                                    多种SK广告形式,打造独属于自己的广告平台,专业技术接入各种主流的广告联盟广告,让每一条广告都可以实现最大利润。一站式App商业流量变现,100%对标管理策略,有着丰富的管理经验以及让所有会员盈利的多种方法。
                                </span>
                            </div>
                        </transition>
                    </div>
                    <div class="tupian_2_rq" @mouseover="onMouseOver1" @mouseleave="onMouseLeave1">
                        <transition name="fade">
                            <div v-if="!isMouseOver1" class="tupian_wz_zz">
                                <span class="tupian_wz">视频播放与植入</span>
                                <span class="tupian_wz_en">Video playback and implantation</span>
                            </div>
                        </transition>
                        <transition name="fade">
                            <div v-if="isMouseOver1" class="tupian_wz_zz_js">
                                <span class="tupian_wz_js">
                                    在视频播放方面,融视汇拥有专业的技术和经验丰富的资深团队,采用OSS大数据存储方式,能够为客户提供高质量高清晰的视频素材的同时,也能保证流畅性。我们还与多家主流媒体和广告平台建立了紧密的合作关系,能够将客户的广告投放到更广泛的受众群体中,实现精准覆盖和高效传播。
                                </span>
                            </div>
                        </transition>
                    </div>
                    <div class="tupian_3_rq" @mouseover="onMouseOver2" @mouseleave="onMouseLeave2">
                        <transition name="fade">
                            <div v-if="!isMouseOver2" class="tupian_wz_zz">
                                <span class="tupian_wz">公司前景与趋势</span>
                                <span class="tupian_wz_en">Conpany prospects and trends</span>
                            </div>
                        </transition>
                        <transition name="fade">
                            <div v-if="isMouseOver2" class="tupian_wz_zz_js">
                                <span class="tupian_wz_js">
                                    未来广告的发布平台会更加多元化,不过随着时代的发展,会有不同的传播形式和传播方式出现,随着互联网的发展,以及市场的需求,更多的广告商开始在各大APP投放广告,根据广告行业的数据,广告商每天通过各大APP广告投流的资金在7-8亿,通过影视平台及游戏平台所消耗的超过86.4%。如果广告商付出和收入不成正比,会出现诸多广告商面临倒闭风险,为了抓住商机,我司愿倾尽所能与各大广告商、影视平台以及我们的各位掘金者互惠互利,实现共赢!
                                </span>
                            </div>
                        </transition>
                    </div>
                </div>
                <div class="tupian_3_wz_rq">
                    <span class="tupian_3_wz">融视汇是一家专注于广告三方服务的综合性公司</span>
                    <span class="tupian_3_wz">致力于为企业提供一站式的广告解决方案</span>
                    <span class="tupian_3_wz">公司汇聚业内资深专家和顶尖技术精英</span>
                    <span class="tupian_3_wz">为客户提供从广告策划、设计、制作到投放、优化、分析等全链条服务</span>
                </div>
            </div>
            <div class="beijing_3">
                <div class="sanbu">
                    <div class="buzhou">
                        <img class="buzhou_tupian" :src="cdn+'shouye/33.png'" />
                        <span class="buzhou_wz">第一步 下载客户端</span>
                    </div>
                    <div class="buzhou">
                        <img class="buzhou_tupian" :src="cdn+'shouye/66.png'" />
                        <span class="buzhou_wz">第二步 激活账号</span>
                    </div>
                    <div class="buzhou">
                        <img class="buzhou_tupian" :src="cdn+'shouye/55.png'" />
                        <span class="buzhou_wz">第三步 查看收益</span>
                    </div>
                </div>
                <div class="wzzzz">
                    <span class="beijing3_wz_1" @click="wzzzz_1">赚钱计划</span>
                    <span class="beijing3_wz_2" @click="wzzzz_2">即刻下载</span>
                </div>
            </div>
            <div class="duoicon_zz">
                <div class="duoicon">
                    <div class="di_1">
                        <div class="d1">
                            <img :src="icons[0]" class="icon_sy" />
                            <img :src="icons[1]" class="icon_sy" />
                            <img :src="icons[2]" class="icon_sy" />
                            <img :src="icons[3]" class="icon_sy" />
                            <img :src="icons[4]" class="icon_sy" />
                            <img :src="icons[5]" class="icon_sy" />
                            <img :src="icons[6]" class="icon_sy" />
                            <img :src="icons[7]" class="icon_sy" />
                            <img :src="icons[8]" class="icon_sy" />
                            <img :src="icons[9]" class="icon_sy" />
                            <img :src="icons[10]" class="icon_sy" />
                        </div>
                        <div v-show="icon_flag" class="d1">
                            <img :src="icons1[0]" class="icon_sy" />
                            <img :src="icons1[1]" class="icon_sy" />
                            <img :src="icons1[2]" class="icon_sy" />
                            <img :src="icons1[3]" class="icon_sy" />
                            <img :src="icons1[4]" class="icon_sy" />
                            <img :src="icons1[5]" class="icon_sy" />
                            <img :src="icons1[6]" class="icon_sy" />
                            <img :src="icons1[7]" class="icon_sy" />
                            <img :src="icons1[8]" class="icon_sy" />
                            <img :src="icons1[9]" class="icon_sy" />
                            <img :src="icons1[10]" class="icon_sy" />
                        </div>
                    </div>
                    <div class="di_1">
                        <div class="d2">
                            <img :src="icons[11]" class="icon_sy" />
                            <img :src="icons[12]" class="icon_sy" />
                            <img :src="icons[13]" class="icon_sy" />
                            <img :src="icons[14]" class="icon_sy" />
                            <img :src="icons[15]" class="icon_sy" />
                            <img :src="icons[16]" class="icon_sy" />
                            <img :src="icons[17]" class="icon_sy" />
                            <img :src="icons[18]" class="icon_sy" />
                            <img :src="icons[19]" class="icon_sy" />
                            <img :src="icons[20]" class="icon_sy" />
                            <img :src="icons[21]" class="icon_sy" />
                        </div>
                        <div v-show="icon_flag" class="d2">
                            <img :src="icons1[11]" class="icon_sy" />
                            <img :src="icons1[12]" class="icon_sy" />
                            <img :src="icons1[13]" class="icon_sy" />
                            <img :src="icons1[14]" class="icon_sy" />
                            <img :src="icons1[15]" class="icon_sy" />
                            <img :src="icons1[16]" class="icon_sy" />
                            <img :src="icons1[17]" class="icon_sy" />
                            <img :src="icons1[18]" class="icon_sy" />
                            <img :src="icons1[19]" class="icon_sy" />
                            <img :src="icons1[20]" class="icon_sy" />
                            <img :src="icons1[21]" class="icon_sy" />
                        </div>
                    </div>
                    <div class="di_1">
                        <div class="d1">
                            <img :src="icons[22]" class="icon_sy" />
                            <img :src="icons[23]" class="icon_sy" />
                            <img :src="icons[24]" class="icon_sy" />
                            <img :src="icons[25]" class="icon_sy" />
                            <img :src="icons[26]" class="icon_sy" />
                            <img :src="icons[27]" class="icon_sy" />
                            <img :src="icons[28]" class="icon_sy" />
                            <img :src="icons[29]" class="icon_sy" />
                            <img :src="icons[30]" class="icon_sy" />
                            <img :src="icons[31]" class="icon_sy" />
                            <img :src="icons[32]" class="icon_sy" />
                        </div>
                        <div v-show="icon_flag" class="d1">
                            <img :src="icons1[22]" class="icon_sy" />
                            <img :src="icons1[23]" class="icon_sy" />
                            <img :src="icons1[24]" class="icon_sy" />
                            <img :src="icons1[25]" class="icon_sy" />
                            <img :src="icons1[26]" class="icon_sy" />
                            <img :src="icons1[27]" class="icon_sy" />
                            <img :src="icons1[28]" class="icon_sy" />
                            <img :src="icons1[29]" class="icon_sy" />
                            <img :src="icons1[30]" class="icon_sy" />
                            <img :src="icons1[31]" class="icon_sy" />
                            <img :src="icons1[32]" class="icon_sy" />
                        </div>
                    </div>
                    <div class="di_2">
                        <div class="d2">
                            <img :src="icons[33]" class="icon_sy" />
                            <img :src="icons[34]" class="icon_sy" />
                            <img :src="icons[35]" class="icon_sy" />
                            <img :src="icons[36]" class="icon_sy" />
                            <img :src="icons[37]" class="icon_sy" />
                            <img :src="icons[38]" class="icon_sy" />
                            <img :src="icons[39]" class="icon_sy" />
                            <img :src="icons[40]" class="icon_sy" />
                            <img :src="icons[41]" class="icon_sy" />
                            <img :src="icons[42]" class="icon_sy" />
                            <img :src="icons[43]" class="icon_sy" />
                        </div>
                        <div v-show="icon_flag" class="d2">
                            <img :src="icons1[33]" class="icon_sy" />
                            <img :src="icons1[34]" class="icon_sy" />
                            <img :src="icons1[35]" class="icon_sy" />
                            <img :src="icons1[36]" class="icon_sy" />
                            <img :src="icons1[37]" class="icon_sy" />
                            <img :src="icons1[38]" class="icon_sy" />
                            <img :src="icons1[39]" class="icon_sy" />
                            <img :src="icons1[40]" class="icon_sy" />
                            <img :src="icons1[41]" class="icon_sy" />
                            <img :src="icons1[42]" class="icon_sy" />
                            <img :src="icons1[43]" class="icon_sy" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import global from '@/api/global/global.vue';

export default {
    name: 'shouye',
    data() {
        return {
            cdn:global.cdn,
            lbt: [
                {
                    imgSrc: global.cdn+'shouye/image5.png',
                },
                {
                    imgSrc: global.cdn+"shouye/image6.jpg",
                },
                {
                    imgSrc: global.cdn+"shouye/image7_1.jpg",
                },
                {
                    imgSrc: global.cdn+"shouye/image9.png",
                }
            ],
            isMouseOver: false,
            isMouseOver1: false,
            isMouseOver2: false,
            icon_flag: true,
            timer: '',
            timer1: '',
            timer_f: '',
            icons1: [require("@/assets/shouye/icon/23魔方.png"),
            require("@/assets/shouye/icon/交易猫.png"),
            require("@/assets/shouye/icon/他趣.png"),
            require("@/assets/shouye/icon/会玩.png"),
            require("@/assets/shouye/icon/传奇.png"),
            require("@/assets/shouye/icon/体重日记本.png"),
            require("@/assets/shouye/icon/儿歌点点.png"),
            require("@/assets/shouye/icon/凹凸计划.png"),
            require("@/assets/shouye/icon/南风来电秀.png"),
            require("@/assets/shouye/icon/友玩陪玩.png"),
            require("@/assets/shouye/icon/叫叫绘本.png"),
            require("@/assets/shouye/icon/吉工宝.png"),
            require("@/assets/shouye/icon/同城约会吧.png"),
            require("@/assets/shouye/icon/同城陌约.png"),
            require("@/assets/shouye/icon/咕咚.png"),
            require("@/assets/shouye/icon/品租.png"),
            require("@/assets/shouye/icon/唱吧.png"),
            require("@/assets/shouye/icon/天气印象.png"),
            require("@/assets/shouye/icon/好搭盒子.png"),
            require("@/assets/shouye/icon/宝宝巴士.png"),
            require("@/assets/shouye/icon/小伴龙.png"),
            require("@/assets/shouye/icon/小红书.png"),
            require("@/assets/shouye/icon/开心休闲乐.png"),
            require("@/assets/shouye/icon/彩虹猫.png"),
            require("@/assets/shouye/icon/心潮.png"),
            require("@/assets/shouye/icon/心遇.png"),
            require("@/assets/shouye/icon/快数.png"),
            require("@/assets/shouye/icon/悟空浏览器.png"),
            require("@/assets/shouye/icon/探探.png"),
            require("@/assets/shouye/icon/携程旅行.png"),
            require("@/assets/shouye/icon/日杂相机.png"),
            require("@/assets/shouye/icon/木鸟民宿.png"),
            require("@/assets/shouye/icon/本地陌聊.png"),
            require("@/assets/shouye/icon/柠檬胎动.png"),
            require("@/assets/shouye/icon/橙橙心理.png"),
            require("@/assets/shouye/icon/比心.png"),
            require("@/assets/shouye/icon/涂个壁纸.png"),
            require("@/assets/shouye/icon/火柴人联盟2.png"),
            require("@/assets/shouye/icon/甜遇.png"),
            require("@/assets/shouye/icon/皮皮虾.png"),
            require("@/assets/shouye/icon/贴贴.png"),
            require("@/assets/shouye/icon/趣射箭.png"),
            require("@/assets/shouye/icon/途风旅游.png"),
            require("@/assets/shouye/icon/酷狗铃声.png"),],
            icons: [
                require("@/assets/shouye/icon/23魔方.png"),
                require("@/assets/shouye/icon/交易猫.png"),
                require("@/assets/shouye/icon/他趣.png"),
                require("@/assets/shouye/icon/会玩.png"),
                require("@/assets/shouye/icon/传奇.png"),
                require("@/assets/shouye/icon/体重日记本.png"),
                require("@/assets/shouye/icon/儿歌点点.png"),
                require("@/assets/shouye/icon/凹凸计划.png"),
                require("@/assets/shouye/icon/南风来电秀.png"),
                require("@/assets/shouye/icon/友玩陪玩.png"),
                require("@/assets/shouye/icon/叫叫绘本.png"),
                require("@/assets/shouye/icon/吉工宝.png"),
                require("@/assets/shouye/icon/同城约会吧.png"),
                require("@/assets/shouye/icon/同城陌约.png"),
                require("@/assets/shouye/icon/咕咚.png"),
                require("@/assets/shouye/icon/品租.png"),
                require("@/assets/shouye/icon/唱吧.png"),
                require("@/assets/shouye/icon/天气印象.png"),
                require("@/assets/shouye/icon/好搭盒子.png"),
                require("@/assets/shouye/icon/宝宝巴士.png"),
                require("@/assets/shouye/icon/小伴龙.png"),
                require("@/assets/shouye/icon/小红书.png"),
                require("@/assets/shouye/icon/开心休闲乐.png"),
                require("@/assets/shouye/icon/彩虹猫.png"),
                require("@/assets/shouye/icon/心潮.png"),
                require("@/assets/shouye/icon/心遇.png"),
                require("@/assets/shouye/icon/快数.png"),
                require("@/assets/shouye/icon/悟空浏览器.png"),
                require("@/assets/shouye/icon/探探.png"),
                require("@/assets/shouye/icon/携程旅行.png"),
                require("@/assets/shouye/icon/日杂相机.png"),
                require("@/assets/shouye/icon/木鸟民宿.png"),
                require("@/assets/shouye/icon/本地陌聊.png"),
                require("@/assets/shouye/icon/柠檬胎动.png"),
                require("@/assets/shouye/icon/橙橙心理.png"),
                require("@/assets/shouye/icon/比心.png"),
                require("@/assets/shouye/icon/涂个壁纸.png"),
                require("@/assets/shouye/icon/火柴人联盟2.png"),
                require("@/assets/shouye/icon/甜遇.png"),
                require("@/assets/shouye/icon/皮皮虾.png"),
                require("@/assets/shouye/icon/贴贴.png"),
                require("@/assets/shouye/icon/趣射箭.png"),
                require("@/assets/shouye/icon/途风旅游.png"),
                require("@/assets/shouye/icon/酷狗铃声.png"),
            ]
        };
    },
    methods: {
        randomArray(arr) {
            var stack = []
            while (arr.length) {
                var index = parseInt(Math.random() * arr.length)
                stack.push(arr[index])
                arr.splice(index, 1)
            }
            return stack
        },
        valchange() {
            this.icons = this.randomArray(this.icons)
        },
        valchange1() {
            this.icons1 = this.randomArray(this.icons1)
        },
        valchange_f() {
            // this.icon_flag = true
            this.timer1 = setInterval(this.valchange1, 10000)
        },
        onMouseLeave() {
            this.isMouseOver = false
        },
        onMouseOver() {
            this.isMouseOver = true
        },
        onMouseLeave1() {
            this.isMouseOver1 = false
        },
        onMouseOver1() {
            this.isMouseOver1 = true
        },
        onMouseLeave2() {
            this.isMouseOver2 = false
        },
        onMouseOver2() {
            this.isMouseOver2 = true
        },
        b4_1() {
            window.open('https://www.iqiyi.com/', '_blank')
        },
        b4_2() {
            window.open('https://v.qq.com/', '_blank')
        },
        b4_3() {
            window.open('https://www.youku.com/', '_blank')
        },
        b4_4() {
            window.open('https://www.ixigua.com/', '_blank')
        },
        wzzzz_1() {
            this.$router.push({ path: '/getVip' })
        },
        wzzzz_2() {
            this.$router.push({ path: '/download' })
        }
    },
    mounted() {
        this.timer_f = setTimeout(this.valchange_f, 5000)
        this.timer = setInterval(this.valchange, 10000)
    }
}

</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&family=Poppins:wght@400;600&display=swap');

.main {
    width: 100vw;

}

.wenzi {
    position: absolute;
    top: 7vw;
    left: 10vw;
    color: white;
    text-shadow: 0 8px 2px rgba(0, 0, 0, 1);
}

.lbt-title {
    font-size: 5vw;
}

.lbt-sub {
    margin-top: 1vw;
    font-size: 2vw;
    text-shadow: 0 5px 3px rgba(0, 0, 0, 1);
}

.lbt-sub1 {
    margin-top: 0.5vw;
    font-size: 1.5vw;
    text-shadow: 0 4px 3px rgba(0, 0, 0, 1);
}

.lunbo {
    position: relative;
    width: 100vw;
    height: 40vw;
    background-size: 100vw 40vw;
    background-position: center;
    overflow: hidden
}

.lunbo::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 40vw;
    background: linear-gradient(to bottom, rgba(19, 19, 19, 1), rgba(19, 19, 19, 0) 10%, rgba(19, 19, 19, 0) 90%, rgba(19, 19, 19, 1));
    pointer-events: none;
    /* 让遮罩不影响点击事件 */
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
}

.biaoti_4 {
    position: relative;
    background-image: url("https://rongshihui.cdn2.cc/files/assets/shouye/beijing2.jpg");
    height: 40vw;
    width: 100vw;
    background-size: 100vw 40vw;
    background-position: center;
    overflow: hidden
}

.biaoti_4::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 40vw;
    background: linear-gradient(to bottom, rgba(19, 19, 19, 1), rgba(19, 19, 19, 0) 10%, rgba(19, 19, 19, 0) 90%, rgba(19, 19, 19, 1));
    pointer-events: none;
    /* 让遮罩不影响点击事件 */
}

.xinxi_tubiao_4 {
    position: absolute;
    top: 3vw;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}


.zhu {
    color: white;
    font-size: 6vw;
    text-align: center;
    font-family: ziti1;
}


.ci {
    color: white;
    margin-top: 1.2vw;
    font-size: 2vw;
    text-align: center;
    font-family: ziti2;
}

.zhuangzai {
    position: absolute;
    width: 100vw;
    height: 30vw;
    top: 11vw;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.rongqi_zz {
    height: 33vw;
    width: 10vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.wenzi_zz {
    margin-top: -10vw;
    width: 10vw;
    color: white;
    filter: drop-shadow(4px 4px 8px rgb(0, 0, 0));
    font-size: 2.3vw;
    font-family: ziti3;
    text-align: center;
}

.wenzi_zz1 {
    margin-top: -9.5vw;
    width: 10vw;
    color: white;
    filter: drop-shadow(4px 4px 8px rgb(0, 0, 0));
    font-size: 2.3vw;
    font-family: ziti3;
    text-align: center;
}

/* 定义抖动动画 */
@keyframes shake {
    0% {
        transform: translateY(0) rotate(0deg) scale(1); /* 初始状态 */
    }
    25% {
        transform: translateY(-8px) rotate(3deg) scale(1.05); /* 上移 + 轻微旋转 + 放大 */
    }
    50% {
        transform: translateY(6px) rotate(-2deg) scale(0.98); /* 下移 + 反方向旋转 + 轻微缩小 */
    }
    75% {
        transform: translateY(-4px) rotate(1deg) scale(1.02); /* 上移 + 轻微旋转 + 放大 */
    }
    100% {
        transform: translateY(0) rotate(0deg) scale(1); /* 回到原始状态 */
    }
}

/* 给目标组件添加抖动效果 */
.shake-on-hover {
    width: 8vw;
    height: 8vw;
    filter: drop-shadow(2px 4px 8px rgb(0, 0, 0))
}

.shake-on-hover1 {
    margin-top: 0.8vw;
    width: 8vw;
    height: 7vw;
    filter: drop-shadow(2px 4px 8px rgb(0, 0, 0))
}

.shake-on-hover:hover {
    animation: shake 2s ease-in-out infinite; /* 浮动效果，持续2秒，平滑过渡 */
    /* 抖动持续时间和缓动效果 */
    animation-iteration-count: 1;
    /* 抖动次数，设置为 1 次 */
    box-shadow: none;
}

.shake-on-hover1:hover {
    animation: shake 2s ease-in-out infinite; /* 浮动效果，持续2秒，平滑过渡 */
    /* 抖动持续时间和缓动效果 */
    animation-iteration-count: 1;
    /* 抖动次数，设置为 1 次 */
    box-shadow: none;
}

.di {
    position: absolute;
    width: 8vw;
    height: 8vw;
}

.changbeijing {
    position: relative;
    background-image: url("../../assets/shouye/changbeijing.png");
    width: 100vw;
    height: 170vw;
    background-size: cover;
    background-position: center;
    overflow: hidden
}

.beijing_1 {
    position: absolute;
    width: 70vw;
    height: 25vw;
    top: 4vw;
    right: 15vw;
    background-color: #5151532a;
    border-radius: 2vw;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.tubiao_3_zz {
    margin-left: 5vw;
    margin-top: 3vw;
    width: 92vw;
    height: 24vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.tubiao_3 {
    width: 14vw;
    height: 14vw;
}

.tubiao_3:hover {
    animation: shakeScale 0.5s ease-in-out; /* 震撼的缩放 + 摇动动画 */
    animation-iteration-count: 1; /* 动画执行一次 */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3); /* 添加阴影使其更立体 */
}

@keyframes shakeScale {
    0% {
        transform: scale(1) rotate(0deg); /* 初始状态 */
    }
    20% {
        transform: scale(1.1) rotate(10deg); /* 放大 + 轻微旋转 */
    }
    40% {
        transform: scale(0.9) rotate(-10deg); /* 缩小 + 反方向旋转 */
    }
    60% {
        transform: scale(1.05) rotate(5deg); /* 轻微放大 + 旋转 */
    }
    80% {
        transform: scale(1) rotate(-5deg); /* 回到正常大小 + 旋转 */
    }
    100% {
        transform: scale(1) rotate(0deg); /* 最终回到初始状态 */
    }
}

.tubiao_3_wz {
    margin-top: 3vw;
    width: 14vw;
    height: 6vw;
    color: white;
    font-size: 1.5vw;
    text-align: center;
    font-family: ziti3;
}


.bj_1_zz {
    position: absolute;
    width: 80vw;
    height: 11.5vw;
    top: 38vw;
    right: 10vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.beijing_1_wz {
    width: 80vw;
    color: white;
    text-shadow: 0 0.5vw 0.2vw rgb(0, 0, 0);
    font-size: 3vw;
    text-align: center;
    font-family: ziti3;
    margin-top: -3vw;
    animation: floatAnimation 3s ease-in-out infinite, textGlow 3s ease-in-out infinite; /* 持续动画 */

}

@keyframes floatAnimation {
    0% {
        transform: translateY(0);
    }
    25% {
        transform: translateY(-5px); /* 轻微向上浮动 */
    }
    50% {
        transform: translateY(5px); /* 轻微向下浮动 */
    }
    75% {
        transform: translateY(-3px); /* 轻微向上浮动 */
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes textGlow {
    0% {
        text-shadow: 0 0.5vw 0.2vw rgb(0, 0, 0); /* 初始阴影 */
    }
    50% {
        text-shadow: 0 0.5vw 1vw rgba(0, 255, 255, 0.6); /* 轻微发光效果 */
    }
    100% {
        text-shadow: 0 0.5vw 0.2vw rgb(0, 0, 0); /* 恢复初始阴影 */
    }
}

.beijing_1_wz:hover {
    animation: shake21 1s ease-in-out, hoverEffect 0.5s ease-out; /* 不同的动画效果 */
    animation-iteration-count: 1;
}

@keyframes shake21 {
    0% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(-5px);
    }
    50% {
        transform: translateX(5px);
    }
    75% {
        transform: translateX(-5px);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes hoverEffect {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1); /* 放大效果 */
    }
    100% {
        transform: scale(1); /* 恢复原始大小 */
    }
}


.beijing_2_wz {
    width: 80vw;
    color: white;
    text-shadow: 0 0.5vw 0.2vw rgb(0, 0, 0);
    font-size: 3vw;
    text-align: center;
    font-family: ziti3;
    margin-top: 2vw;
    animation: floatAnimation 3s ease-in-out infinite, textGlow 3s ease-in-out infinite; /* 持续动画 */
}

.beijing_2_wz:hover {
    animation: shake21 1s ease-in-out, hoverEffect 0.5s ease-out; /* 不同的动画效果 */
    animation-iteration-count: 1;
}

.beijing_3_wz {
    width: 80vw;
    color: white;
    text-shadow: 0 0.5vw 0.2vw rgb(0, 0, 0);
    font-size: 3vw;
    text-align: center;
    font-family: ziti3;
    margin-top: 2vw;
    animation: floatAnimation 3s ease-in-out infinite, textGlow 3s ease-in-out infinite; /* 持续动画 */
}

.beijing_3_wz:hover {
    animation: shake21 1s ease-in-out, hoverEffect 0.5s ease-out; /* 不同的动画效果 */
    animation-iteration-count: 1;
}

.beijing_2 {
    width: 100vw;
    height: 40vw;
    margin-top: 53vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.tupian_3_zz {
    width: 80vw;
    margin-left: 10vw;
    height: 35vw;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.tupian_1_rq {
    position: relative;
    width: 20vw;
    height: 28vw;
    border-radius: 1vw;
    background-image: url("https://rongshihui.cdn2.cc/files/assets/shouye/tupian1.png");
    background-size: cover;
    background-position: center;
}

.tupian_2_rq {
    position: relative;
    width: 20vw;
    height: 28vw;
    border-radius: 1vw;
    background-image: url("https://rongshihui.cdn2.cc/files/assets/shouye/tupian2.png");
    background-size: cover;
    background-position: center;
}

.tupian_3_rq {
    position: relative;
    width: 20vw;
    height: 28vw;
    border-radius: 1vw;
    background-image: url("https://rongshihui.cdn2.cc/files/assets/shouye/tupian3.png");
    background-size: cover;
    background-position: center;
}

.tupian_wz_zz {
    position: absolute;
    width: 20vw;
    height: 10vw;
    bottom: 0vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    transition: opacity 0.8s ease;
}

/* 定义过渡效果 */
.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.tupian_wz {
    width: 20vw;
    font-size: 2.7vw;
    color: white;
    text-shadow: 0 0.5vw 0.5vw rgba(0, 0, 0, 1);
    text-align: center;
    font-family: ziti1;
}

.tupian_wz_en {
    margin-top: -1vw;
    width: 20vw;
    font-size: 1.2vw;
    text-align: center;
    color: white;
    font-family: ziti3;
}

.tupian_wz_zz_js {
    position: absolute;
    width: 16vw;
    height: 30vw;
    left: 2vw;
    top: 0vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    transition: opacity 0.8s ease;
}

.tupian_wz_js {
    width: 16vw;
    font-size: 1.3vw;
    font-family: ziti3;
    color: white;
    text-shadow: 0 0.5vw 0.5vw rgba(0, 0, 0, 1);
    text-align: center;
}

.tupian_3_wz_rq {
    position: absolute;
    top: 90vw;
    right: 4vw;
    width: 92vw;
    height: 15vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.tupian_3_wz {
    color: white;
    margin-top: 1.5vw;
    width: 92vw;
    height: 8vw;
    text-shadow: 0 0.5vw 0.2vw rgb(0, 0, 0);
    font-size: 2.8vw;
    text-align: center;
    font-family: ziti3;
    animation: floatAnimation1 4s ease-in-out infinite; /* 持续平滑浮动 */
}

@keyframes floatAnimation1 {
    0% {
        transform: translateY(0) scale(1); /* 初始状态 */
    }
    50% {
        transform: translateY(-3px) scale(1.02); /* 轻微上浮和缩放 */
    }
    100% {
        transform: translateY(0) scale(1); /* 恢复初始状态 */
    }
}

.tupian_3_wz:hover {
    animation: shake2 1s ease-in-out;
    /* 抖动持续时间和缓动效果 */
    animation-iteration-count: 1;
    /* 抖动次数，设置为 1 次 */
    box-shadow: none;
}

.beijing_3 {
    position: relative;
    margin-top: 20vw;
    width: 100vw;
    height: 55vw;

}

.sanbu {
    width: 70vw;
    height: 30vw;
    margin-left: 15vw;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

@keyframes shake1 {
    0% {
        transform: translateX(0) rotate(0deg);
    }

    25% {
        transform: translateX(-10px) rotate(-5deg);
    }

    50% {
        transform: translateX(10px) rotate(5deg);
    }

    75% {
        transform: translateX(-10px) rotate(-5deg);
    }

    100% {
        transform: translateX(0) rotate(0deg);
    }
}

.buzhou {
    position: relative;
    width: 20vw;
    height: 28vw;
    // background-color: #414141a9;
    border-radius: 2vw;
}

.buzhou:hover {
    animation: shake1 2s ease-in-out;
    /* 抖动持续时间和缓动效果 */
    animation-iteration-count: 1;
    /* 抖动次数，设置为 1 次 */
    box-shadow: none;
}

.buzhou_tupian {
    position: absolute;
    top: 4vw;
    right: 2.5vw;
    width: 15vw;
    height: 15vw;
}

.buzhou_wz {
    position: absolute;
    top: 20vw;
    width: 20vw;
    font-size: 2vw;
    text-align: center;
    color: white;
    font-family: ziti4;
}

.wzzzz {
    position: relative;
    width: 100vw;
    height: 40vw;
}

.beijing3_wz_1 {
    position: absolute;
    font-size: 5vw;
    margin-top: 1vw;
    margin-left: 19vw;
    color: white;
    text-shadow: 0 1vw 0.5vw rgba(0, 0, 0, 1);
    font-family: ziti3;
    // animation: floatAndBounce 3s ease-in-out infinite, colorChange 4s ease-in-out infinite; /* 持续浮动、弹跳和颜色变化 */
}

// @keyframes floatAndBounce {
//     0% {
//         transform: translateY(0) rotate(0deg) scale(1); /* 初始状态 */
//     }
//     25% {
//         transform: translateY(-10px) rotate(3deg) scale(1.05); /* 向上弹跳，轻微旋转和放大 */
//     }
//     50% {
//         transform: translateY(0) rotate(-3deg) scale(1); /* 恢复初始状态，反向旋转 */
//     }
//     75% {
//         transform: translateY(5px) rotate(2deg) scale(1.03); /* 轻微下移和旋转 */
//     }
//     100% {
//         transform: translateY(0) rotate(0deg) scale(1); /* 恢复原始状态 */
//     }
// }

// /* 持续颜色变化 */
// @keyframes colorChange {
//     0% {
//         color: white; /* 初始颜色 */
//     }
//     50% {
//         color: #ff6347; /* 番茄红 */
//     }
//     100% {
//         color: white; /* 恢复白色 */
//     }
// }

.beijing3_wz_1:hover {
    animation: shake2 1s ease-in-out;
    /* 抖动持续时间和缓动效果 */
    animation-iteration-count: 1;
    /* 抖动次数，设置为 1 次 */
    box-shadow: none;
}

.beijing3_wz_2 {
    position: absolute;
    font-size: 5vw;
    margin-top: 1vw;
    margin-left: 60vw;
    color: white;
    text-shadow: 0 1vw 0.5vw rgba(0, 0, 0, 1);
    font-family: ziti3;
    animation: floatAndBounce 3s ease-in-out infinite, colorChange 4s ease-in-out infinite; /* 持续浮动、弹跳和颜色变化 */

}

.beijing3_wz_2:hover {
    animation: shake2 1s ease-in-out;
    /* 抖动持续时间和缓动效果 */
    animation-iteration-count: 1;
    /* 抖动次数，设置为 1 次 */
    box-shadow: none;
}

.duoicon_zz {
    position: relative;
    background-color: #d3dce652;
    backdrop-filter: blur(2px);
    top: -12vw;
    width: 100vw;
    height: 14vw;

}

.duoicon {
    position: absolute;
    top: 1vw;
    width: 100vw;
    height: 12vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.icon_sy {
    width: 4vw;
    height: 4vw;
}

.di_1 {
    width: 100vw;
    /* 容器宽度占满屏幕 */
    height: 7vw;
    /* 设置容器高度 */
    overflow: hidden;
    /* 隐藏超出容器的部分 */
    position: relative;
}

.d1 {
    position: absolute;
    width: 100vw;
    /* 容器宽度占满屏幕 */
    height: 7vw;
    /* 设置容器高度 */
    display: flex;
    /* 使用 flex 布局，确保图标在同一行 */
    flex-direction: row;
    justify-content: space-around;
    animation: scrollText 10s linear infinite;
    /* 动画，10秒一次 */
}

.d1:nth-child(2) {
    animation-delay: 5s;
}

.di_2 {
    width: 100vw;
    /* 容器宽度占满屏幕 */
    height: 7vw;
    /* 设置容器高度 */
    overflow: hidden;
    /* 隐藏超出容器的部分 */
    position: relative;
}

.d2 {
    position: absolute;
    width: 100vw;
    /* 容器宽度占满屏幕 */
    height: 7vw;
    /* 设置容器高度 */
    display: flex;
    /* 使用 flex 布局，确保图标在同一行 */
    flex-direction: row;
    justify-content: space-around;
    animation: scrollText 10s linear infinite;
    /* 动画，10秒一次 */
    animation-delay: 0.22s;
}

.d2:nth-child(2) {
    animation-delay: 5.22s;
}

.di_3 {
    width: 100vw;
    /* 容器宽度占满屏幕 */
    height: 7vw;
    /* 设置容器高度 */
    overflow: hidden;
    /* 隐藏超出容器的部分 */
    position: relative;
}

.d3 {
    position: absolute;
    width: 100vw;
    /* 容器宽度占满屏幕 */
    height: 7vw;
    /* 设置容器高度 */
    display: flex;
    /* 使用 flex 布局，确保图标在同一行 */
    flex-direction: row;
    justify-content: space-around;
    animation: scrollText 10s linear infinite;
    /* 动画，10秒一次 */
}

.d3:nth-child(2) {
    animation-delay: 5s;
}

.di_4 {
    width: 100vw;
    /* 容器宽度占满屏幕 */
    height: 7vw;
    /* 设置容器高度 */
    overflow: hidden;
    /* 隐藏超出容器的部分 */
    position: relative;
}

.d4 {
    position: absolute;
    width: 100vw;
    /* 容器宽度占满屏幕 */
    height: 7vw;
    /* 设置容器高度 */
    display: flex;
    /* 使用 flex 布局，确保图标在同一行 */
    flex-direction: row;
    justify-content: space-around;
    animation: scrollText 10s linear infinite;
    /* 动画，10秒一次 */
    animation-delay: 0.22s;
}

.d4:nth-child(2) {
    animation-delay: 5.22s;
}

@keyframes scrollText {
    0% {
        transform: translateX(100%);
        /* 从右边开始 */
    }

    100% {
        transform: translateX(-100%);
        /* 滚动到左边 */
    }
}

@keyframes shake2 {
    0% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(-5px);
    }

    50% {
        transform: translateX(5px);
    }

    55% {
        transform: translateX(-5px);
    }

    75% {
        transform: translateX(5px);
    }

    100% {
        transform: translateX(0);
    }
}
</style>