<template>
    <div class="main">
        <div class="window">
            <span class="title">购卡</span>
            <div v-for="(imgSrc, index) in images" :key="index" :class="{ 'active': selectedIndex == index }" class="mouth_box">
                <img :src="imgSrc" class="mouth" @click="selectDiv(index)" />
            </div>
        </div>
    </div>
</template>

<script>
import global from '@/api/global/global.vue';

export default {
    name: 'Gouka',
    data() {
        return {
            option: 0,
            selectedIndex: null,
            images: [
                global.cdn + 'huoquhuiyuanka/mouth.png',
                global.cdn + 'huoquhuiyuanka/season.png',
                global.cdn + 'huoquhuiyuanka/year.png'
            ]
        }
    },
    methods: {
        selectDiv(index) {
            if (this.selectedIndex == index) {
                this.option = 0;
                this.selectedIndex = null;
            } else {
                this.selectedIndex = index;
                if (index == '0') {
                    this.option = 139;
                } else if (index == '1') {
                    this.option = 450;
                } else if (index == '2') {
                    this.option = 1620;
                } else {
                    this.option = 0;
                }
            }
            this.$emit('data-from-gouka', this.option);
        }
    }
}
</script>

<style long="scss" scoped>
.main {
    width: 100%;
    height: 100%;
}

.window {
    width: 40vw;
    height: 45vw;
}

.title {
    width: 40vw;
    margin-left: 1vw;
    font-size: 3vw;
    font-family: ziti1;
    line-height: 4vw;
    color: white;
}

.mouth_box {
    width: 28vw;
    margin-left: 8vw;
    margin-top: 3vw;
    height: 20%;
    border-radius: 1vw;
}

.mouth_box:hover {
    border-radius: 1vw;
    border: 0.2vw solid grey;
}

.mouth {
    width: 28vw;
    height: 100%;
    border-radius: 1vw;
}

.active {
    border-radius: 1vw;
    border: 0.2vw solid grey;
}
</style>