<template>
    <div class="main">
        <div class="xinxi" v-loading="loading1">
            <div class="useinfo">
                <span class="da">基 本 信 息</span>
                <div class="info">
                    <span class="xiao">账号：{{ userid }}</span>
                    <span class="xiao">会员等级：{{ vip_level }}</span>
                    <span class="xiao">激活码有效期：{{ datetime_dq }}</span>
                </div>
            </div>
            <div class="status">
                <div class="statue_box">
                    <span class="middle">积分余额</span>
                    <div class="data_box">
                        <span class="data">{{ jinbi }}</span>
                    </div>
                </div>
                <div class="statue_box">
                    <span class="middle">可提现金额</span>
                    <div class="data_box">
                        <span class="data">{{ tixian }}</span>
                    </div>
                </div>
                <div class="statue_box">
                    <span class="middle">已提现金额</span>
                    <div class="data_box">
                        <span class="data">{{ yitixian }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="shouyi" v-loading="loading2">
            <span class="da_sy">收 益 统 计</span>
            <div class="jilu">
                <vw-table :columns="columns" :data="tableData" />
            </div>
            <div class="tiao">
                <span>*仅展示最新11条记录，详细请点击左侧任务记录</span>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import global from '@/api/global/global.vue';
import Cookies from 'js-cookie'
import VwTable from './zujian/VwTable.vue';

export default {
    components: {
        VwTable
    },
    data() {
        return {
            userid: '未登录',
            vip_level: '未登录',
            datetime_dq: '未登录',
            jinbi: 0,
            tixian: 0,
            yitixian: 0,
            tableData: [],
            tiaoshu: 0,
            loading1: false,
            loading2: false,
            columns: [
                { label: '收支类型', prop: 'transactionType', width: '30vw' }, // 自定义列宽
                { label: '时间', prop: 'createdBy', width: '20vw' },  // 自定义列宽
                { label: '积分', prop: 'points', width: '50vw' }, // 自定义列宽
            ],
            i: 0,
            n: 0
        }
    },
    methods: {
        async fetchData() {
            this.loading1 = true;
            this.loading2 = true;
            await axios.get(global.url + '/getInfo', {
                headers: { Authorization: "Bearer " + Cookies.get("token") }
            }).then(response => {
                if (response.data.msg == "操作成功") {
                    this.loading1 = false;
                    this.userid = response.data.user.userName;
                    if (response.data.roles[0] == "vip") {
                        this.vip_level = '普通会员';
                    } else if (response.data.roles[0] == "gold_vip") {
                        this.vip_level = '黄金会员';
                    } else if (response.data.roles[0] == "silver_vip") {
                        this.vip_level = '铂金会员';
                    } else if (response.data.roles[0] == "diamond_vip") {
                        this.vip_level = '钻石会员';
                    }
                } else {
                    this.loading1 = false;
                }
            }).catch(error => {
                this.loading1 = false;
                this.$message("网络错误！请检查网络或联系客服");
            });
            await axios.get(global.url + '/user/my', {
                headers: { Authorization: "Bearer " + Cookies.get("token") }
            }).then(response => {
                if (response.status == 200) {                   
                    if(this.vip_level == '普通会员'){
                        if(response.data.isActive == 1){
                            this.vip_level = 'VIP'
                        }
                    }
                    this.datetime_dq = response.data.endDate;
                    this.jinbi = response.data.totalPoints;
                    this.tixian = this.jinbi / 10000;
                    this.yitixian = response.data.spentPoints / 10000;
                    this.loading1 = false;
                } else {
                    this.loading1 = false;
                }
            }).catch(error => {
                this.loading1 = false;
            })
            await axios.get(global.url + '/userPoint?pageNum=1&pageSize=11', {
                headers: { Authorization: "Bearer " + Cookies.get("token") }
            }).then(response => {
                if (response.data.msg == "查询成功") {
                    this.loading2 = false
                    this.tableData = response.data.rows
                    this.n = response.data.total;
                    if (0 < this.n < 11) {
                        for (this.i = 0; this.i < this.n; this.i++) {
                            if (this.tableData[this.i].transactionType == 'video') {
                                this.tableData[this.i].transactionType = '观看视频'
                            } else if (this.tableData[this.i].transactionType == 'game') {
                                this.tableData[this.i].transactionType = '下载应用'
                            }else if (this.tableData[this.i].transactionType == 'ad') {
                                this.tableData[this.i].transactionType = '浏览广告'
                            }
                        }
                    }else if(this.n >= 11){
                        for (this.i = 0; this.i < 11; this.i++) {
                            if (this.tableData[this.i].transactionType == 'video') {
                                this.tableData[this.i].transactionType = '观看视频'
                            } else if (this.tableData[this.i].transactionType == 'game') {
                                this.tableData[this.i].transactionType = '下载应用'
                            }else if (this.tableData[this.i].transactionType == 'ad') {
                                this.tableData[this.i].transactionType = '浏览广告'
                            }
                        }
                    }
                } else {
                    this.loading2 = false
                }
            }).catch(error => {
                this.loading2 = false
                
            })
        }
    },
    mounted() {
        this.fetchData();
    }
}
</script>

<style long="scss" scoped>
.main {
    width: 100%;
    height: 100%;
}

.xinxi {
    width: 100%;
    height: 35%;
    color: white;
    background-color: rgba(0, 0, 0, 0.355);
    backdrop-filter: blur(6px);
    border-radius: 1vw;
}

.useinfo {
    width: 100%;
    height: 55%;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: space-evenly;
    border: none;
}

.info {
    width: 100%;
    height: 20%;
    text-align: start;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.da {
    font-size: 2.8vw;
    font-family: ziti3;
}

.statue_box {
    text-align: center;
    border: none;
    width: 15%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.middle {
    font-size: 1.8vw;
    font-family: ziti3;
    border: none;
}

.xiao {
    font-size: 1.6vw;
    font-family: ziti3;
}

.status {
    width: 100%;
    height: 30%;
    color: white;
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

}

.data_box {
    margin-top: 10%;
    background-color: rgb(57, 57, 57);
    width: 100%;
    height: 60%;
    border: none;
    border-radius: 1vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.data {
    width: 100%;
    color: rgb(194, 164, 89);
    font-size: 1.4vw;
    font-family: ziti3;
    text-align: center;
}

.shouyi {
    position: relative;
    margin-top: 1%;
    width: 100%;
    height: 64%;
    background-color: rgba(0, 0, 0, 0.355);
    backdrop-filter: blur(6px);
    border-radius: 1vw;
}

.da_sy {
    position: absolute;
    color: white;
    font-size: 2.8vw;
    font-family: ziti3;
    top: 0.5vw;
    left: 24.5vw;
}

.jilu {
    position: absolute;
    top: 13%;
    left: 11%;
    width: 90%;
    height: 75%;
    color: white;
    font-size: 1.2vw;
    font-family: ziti3;
}

.tiao {
    position: absolute;
    bottom: 2%;
    left: 5%;
    color: white;
    font-size: 1vw;
    font-family: ziti3;
}
</style>